
#search_placeholder_light::-webkit-input-placeholder {
    color: #5e006659;
}

#search_placeholder_dark::-webkit-input-placeholder {
    color: #81d3e67b;
}
.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes slideInFromLeft {
  from {
    opacity: 0%;
    height: 100px;
    width: 100px;
    left: 92.5%;
    top: -16px;
  } 
  to {
    opacity: 100%;
    /* left:20px; */
    height: 100%;
    width: 100%;
    left: none;
    top: none;
  } 
}
/*-----------------------------------------*/

/* .container {
  background:#fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-flow: row;
} */


.left-panel {
  width: 380px;
  background: #E0F3F4;
  flex-grow: 1;
  border-right: 1px solid #6acfd5;
  display: flex;
  flex-flow: column;
  min-height: 0;
  box-shadow: 0px 0px 10px 1px #717171;
  z-index: 1000;
  /* border: solid black */
}

.view-panel {
  /* background: white; */
  display: none;
  flex:1;
  flex-flow: column;
  padding: 0px;
  margin-top: 0px;
  /* color: white; */
  /* background:#fff; */
  text-align: center;
  position: relative;
  /* border:5px solid pink; */
}

/* .left-panel .search-bar {
  flex: 0 0 60px;
  padding:10px 5px 0px 5px ;
} */

.filter-panel {
  display: flex;
  flex-flow: column;
  flex: 1;
  /* min-height: 0; */
}

/* .filter-option-btn {
  color:white;
  font-size: 11px;
  max-width: 140px;
} */

.list-view {
  /* flex-grow: 1; */
  flex: 1 1 300px;
  display: flex;
  flex-flow: column;
  margin:5px;
  min-height: 0;
}

.attr-view {
  /* flex-grow: 1; */
  flex: 1 1 300px;
  display: flex;
  flex-flow: column;
  /* background: rgb(239, 239, 239); */
  margin:5px;
  min-height: 0;
  /* border:1px solid rgb(218, 65, 65); */
}

.list-view .list-options {
  flex:0 0 25px;
  /* background: white; */
  /* border:1px solid black; */
  margin: 0px 1px 1px 1px;
  display: flex;
  align-items: center; /* align vertical */
}

.ele-list {
  overflow: scroll;
  list-style-type: none;
  padding: 0;
}

/* .ele-tile-card{
  height: 150px;
} */

.list-view #list-items-container {
  /* background: rgb(239, 239, 239); */
  flex-grow: 1;
  overflow: auto;
  min-height: 0;
}

/* .flash{
  background: red;
} */

/* .example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
} */


.list-ele {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  margin:1px;
  height: 100px;
  font-size: small;
  font-weight: 300;
}

.list-view.sel {
  background: rgb(187, 64, 64);
}

.list-view.nosel {
  background: #e6e3e3;
}

.list-ele:hover {
  background: rgb(194, 220, 204);
  font-weight: 500;
}

.left-panel .list-ele .tag-icon {
  margin-right: 4px !important;
}

.left-panel .list-ele .tag-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}


.map-marker-text {
  font-weight: 600;
  margin-top:0px !important;
}

.map-marker-text-none {
  display: none;
}
/* .leaflet-container {
  height: 800px;
  width: 100%;
} */

#mapid {
  flex:1;
  /* height: 800px; */
  width: 100%;
}

.view-panel .header {
  /* display: block; */
  display:flex;
  flex-flow: row;
  position: absolute;
  top:0;
  width: 100%;
  height: 60px;
  min-height: 0;
  z-index: 700;
}

.floating-menu {
  font-family: sans-serif;
  background: yellowgreen;
  padding: 5px;;
  width: 130px;
  z-index: 100;
  position: fixed;
}

/* .view-content {
  display: flex;
  flex-flow: column;
  justify-content: left;
  font-size: large;
  color: black;
  float: left;
  background:yellow;
} */

.view-panel .header .view-ui {
  /* border:1px solid yellow; */
  flex: 1;
  display: flex;
  flex-flow: row;
}

.view-panel .header .view-ui .view-toggle {
  flex:0 0 140px;
}

/* .view-panel .header .view-ui .view-options {
  flex:2;
  margin-top: 6px; 
} */

.view-panel .header .logo {
  flex:0 0 60px;
  cursor:pointer ;
}

.view-panel .header .logo img{
  margin:12px;
  width: 44px;
}

/* .view-panel .tag-view {
  margin-top: 75px;
  margin-left: 15px;
} */

/* .tag-view .hovered .tag-rect {
  stroke:#5D0066;
  stroke-width: 0.2em;
  opacity: 1;
}
.tag-view .hovered .tag-text {
  font-weight: 500;
}

.tag-view .clicked .tag-rect {
  stroke:#340239;
  stroke-width: 0.2em;
  opacity: 1;
  fill: #A7108E;
}

.tag-view .clicked .tag-text {
  fill: #fff;
} */


.footer { 
  background: grey;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/********************************************/
.leaflet-verticalcenter {
      /********************************************/
      /* Same as .leaflet-top and .leaflet-bottom */
      position: absolute;
      z-index: 1000;
      pointer-events: none;
      /********************************************/
      transform: translateY(-50%);
      padding-top: 10px;
    }
    
    .leaflet-verticalcenter .leaflet-control {
      margin-bottom: 10px;
    }

  /********************************************/
/* .tag-view svg {
  cursor:pointer ;
} */

.tooltipx {
  color: black;
  position: absolute;
  text-align: left;
  width: auto;
  height: auto;
  padding: 5px;
  font-family: Futura;
  font: 12px sans-serif ;
  background: #ADE9FF; /* #FCB8C3FF; */
  border: 0px;
  border-radius: 4px;
  pointer-events: none;
}

.histo-view .selected {
  fill: #bcff9b;
}

.histo-view .not-selected {
  fill: #d2d2d2;
}

.tooltip {
    text-decoration:none;
    position:relative;
    background: #517d4d;
    width: 200px;
    
}
.tooltip span {
    display:none;
}
.tooltip:hover span {
    display:block;
    position:fixed;
    overflow:hidden;
}

.konvajs-content {
        position: absolute !important;
        /* border: 1px solid red; */
        margin-left: 50px;
        margin-top:70px;
    }
.histo-content {
        position: absolute !important;
        /* border: 1px solid red; */
        margin-left: 50px;
        margin-top:70px;
    }
.histo-stage {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}

.histo-legend {
  z-index: 2000;
}

.histo-legend-x {
  /* z-index: 120; */
  display: flex;
  /* padding: 4px; */
  background:none;
  list-style: none;
  margin-top: 0px;
  align-items: flex-end;
  flex-direction: row;
  flex: 1;
  /* padding-left: 35px; */
  /* padding-right: 40px; */
  padding-bottom: 88px;
  /* justify-content: space-evenly;*/
}

.histo-legend-x-box{
  font-size:10px;
}


/* Medium screens */
@media all and (min-width: 980px) {
  /* We tell both sidebars to share a row */
  /* .left-panel {flex:0 0 400px;} */
  .doc-left-panel {width: 400px;}
  .view-panel {display: flex;}
  .left-panel .filter-option-btn {max-width: 120px;}
}

/* Large screens */
@media all and (min-width: 1200px) {
  .doc-left-panel {width: 480px;}
  .left-panel .filter-option-btn {max-width: 140px;}
  .histo-legend-x-box{font-size:14px;}
}